import React from 'react';
import ErrorImage from './Assets/Images/404-error1.png';
import { Typography, Button, Container } from "@mui/material";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        height: "auto",
      }}
    >
      <Typography
        component="img"
        src={ErrorImage}
        alt="404 Error"
        sx={{ width: '100%', maxWidth: '200px'  }}
      />
      <Typography
        component="h1"
        sx={{ fontSize: "2rem", fontWeight: "600", margin: "0.5rem 0" }}
      >
        Oops! Something Went Wrong :)
      </Typography>
      <Typography
        component="p"
        sx={{ fontSize: "1rem", marginBottom: "2rem" }}
      >
        The page you are looking for might have been moved or does not exist.
      </Typography>
      <div className='mb-5 p-2'>
        <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        sx={{ margin: "0.5rem" }}
      >
        Go to Home
      </Button>
      <Button
        component={Link}
        to="/contactus"
        variant="outlined"
        color="primary"
        sx={{ margin: "0.5rem" }}
      >
        Contact Us
      </Button>
      </div>
      
    </Container>
  );
};

export default Error404;

