import React, { useEffect, useState } from "react";
import { Typography } from '@mui/material';

const IQAC_Parameter = () => {

    const IQAC_Parameter_data = {
        PARAMETERS:
        {
            title: "PARAMETERS OF IQAC",
            description: [
                {
                    data: "Various parameters are considered for Quality enhancement, and monitoring of those parameters is done through monthly reports."
                },
                {
                    data: "Some of the critical parameters considered by IQAC for implementation in the University are as follows:"
                }
            ]
        },
        Quality_Education: {
            title: "A. Quality Education:",
            description: [
                {
                    data: "Student-centric teaching methodology in the class by faculty"
                },
                {
                    data: "Uploading the information in e-learning sites by the faculty"
                },
                {
                    data: "Conduction of tutorials properly"
                },
                {
                    data: "Conducting course coordinators meetings regularly for uniformity"
                },
                {
                    data: "Conducting Workshops every Semester related to Core subjects"
                },
                {
                    data: "Procurement of library books"
                },
                {
                    data: "Quality of assignments and question papers"
                },
                {
                    data: "Lesson plan verification by HOD"
                },
                {
                    data: "Analysis and follow up action on the internal exam of students"
                },
                {
                    data: "Conducting a faculty meeting every month"
                },
                {
                    data: "Conducting a staff meeting every month"
                },
                {
                    data: "Conduction of Seminars and GDs"
                },
                {
                    data: "Meetings conducted by various committees in which students are members"
                },
                {
                    data: "Participation of students in sports & other hobby clubs"
                },
                {
                    data: "Conducting counselling periodically"
                },
                {
                    data: "Professional society activities"
                }
            ]

        },
        Academic_Infrastructure: {
            title: "B. Academic Infrastructure:",
            description: [
                {
                    data: "Smart classrooms foster better teaching and learning"
                },
                {
                    data: "Latest technologies for the active involvement of learners in state-of-the-art labs"
                },
                {
                    data: "Developing centers of excellence to share competencies and building capacities"
                },
                {
                    data: "Acquiring, accessibility and preserving information in the library"
                },
                {
                    data: "Modern auditoriums to promote all-round development of learners"
                },
                {
                    data: "Making available the materials needed for achieving one’s goals in campus book stores"
                },
                {
                    data: "Uninterrupted high-speed internet connectivity through Wi-Fi"
                },
                {
                    data: "Satiating different tastes through food courts"
                },
                {
                    data: "Achieving overall health and happiness at DBRAEI Fitness Centre"
                }
            ]

        },
        Related_Activities: {
            title: "C. Placements / Industry-Related Activities",
            description: [
                {
                    data: "T & P Cell to provide campus recruitment training"
                },
                {
                    data: "T & P team interfaces with industry"
                },
                {
                    data: "Career guidance cell exposes and explores varied career & higher education opportunities"
                },
                {
                    data: "Campus recruitment training honing employability skills"
                },
                {
                    data: "Mission R&D develop and support promising student’s careers in product development"
                },
                {
                    data: "Guest lectures by industrial experts expose the students to knowledge beyond the curriculum"
                },
                {
                    data: "Industrial visits and tours aim at practical exposure to organizations"
                },
            ]

        },
        Research_Development: {
            title: "D. Research & Development",
            description: [
                {
                    data: "Faculty publications aim to disseminate one’s research"
                },
                {
                    data: "Student publications aid encourage students for further research"
                },
                {
                    data: "Researching through sponsored and sanctioned projects"
                },
                {
                    data: "Exposure to cutting edge technologies and the latest developments through international conferences"
                },
                {
                    data: "Problem solving and revenue generation by extending consultancy services"
                },
                {
                    data: "Innovation, Incubation and Entrepreneurship cell to encourage enterprising activities"
                },
                {
                    data: "Faculty deputed to Workshops/Seminars/Conferences for up-gradation of knowledge"
                }
            ]

        }

    }
    return (
        <section className="container py-4">
            <div>
                <Typography variant="h6" component="div" sx={{ my: 2 }}>
                    {IQAC_Parameter_data.PARAMETERS.title}
                </Typography>
                <div>
                    <ul>
                        {IQAC_Parameter_data.PARAMETERS.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Quality_Education.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Quality_Education.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Academic_Infrastructure.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Academic_Infrastructure.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Related_Activities.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Related_Activities.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <div>
                <div>
                    <Typography variant="h6" component="div" sx={{ my: 2 }}>
                        {IQAC_Parameter_data.Research_Development.title}
                    </Typography>
                </div>
                <div>
                    <ul>
                        {IQAC_Parameter_data.Research_Development.description.map(items => {
                            return (
                                <li>{items.data}</li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default IQAC_Parameter