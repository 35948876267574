import React from "react";
import Grid from "@mui/material/Grid";
import "../../Styles/placements.css";

const PlacementsCards = () => {
  const placementsData = {
    PlacementCards: [
      {
        id: "1",
        description: "Placements",
      },
      {
        id: "2",
        description: "Placements",
      },
      {
        id: "3",
        description: "Placements",
      },
    ],
  };

  return (
    <Grid container className="placement_container" spacing={2}>
      {placementsData.PlacementCards.map((card) => (
        <Grid item className="placement_grid" 
          key={card.id}
          xs={12}
          sm={6}
          md={4}
          lg={4}
        >
          <div className="placement_card">{card.description}</div>
        </Grid>
      ))}
    </Grid>
  );
};

export default PlacementsCards;
