import React from "react";
import "./Carosel.css";
import Slider from "react-slick";

const Image_Carosel = (props) => {
  const { image_data } = props;

  let slider1 = [];
  let slider2 = [];

  return (
    <div  className="Image_carosel">
      <Slider ref={(slider) => (slider2 = slider)} arrows={false} autoplay={true} dots={true}>
        {image_data &&
          image_data.map((items, index) => {
            return (
              <div key={index}>
                <img
                  src={
                    items === ""
                      ? "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                      : items
                  }
                  width="100%"
                  height={350}
                  alt="events data"
                  onError={(e) =>
                    (e.target.src =
                      "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                  }
                  sx={{objectFit :"cover"}}
                />
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Image_Carosel;
