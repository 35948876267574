import React, { useState, useEffect } from "react";
import "../../Styles/Aboutus.css";
import banner_img from "../../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";
import Qoute_icon from "../../Assets/Icons/qoute.svg";
import { Grid, Typography } from "@mui/material";
import Achived from "../../Component/Achived_componet/Achived";
import employee_img from "../../Assets/Images/empolyee.png";
import CustomBreadcrumbs  from "../../Component/CustomBreadcrumbs";
import HCarosel from "../../Component/Carosel/Header_Carosel";
import KakaPhoto from "../../Assets/Images/Kaka-venkataswamy-2-768x538 1.png";



const About_us = () => {
  const employee_Data = [
    {
      employe_name: "Dr.G. Vivek Venkatswamy",
      designation: "CHAIRMAN",
      description: "MLA (CHENNURU)",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/dr-vivek.jpg",
    },
    {
      employe_name: "Dr.G. Vinod",
      designation: "SECRETARY",
      description: "MLA (BELLAMPALLI)",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/G-Vinod-Kumar.jpg",
    },
    { 
      employe_name: "Dr.G. Saroja Vivekanand",
      designation: "CORRESPONDENT",
      description: "Managing Director – Visaka Industries",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/g-saroja.jpg",
    },
    {
      employe_name: "G. RAMA",
      designation: "JOINT SECRETARY",
      description: "DBRAEI Joint secretary",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/g-rama.jpg",
    },
    {
      employe_name: "PV Ramana Kumar",
      designation: "JOINT SECRETARY",
      description: "DBRAEI Joint secretary",
      image_path:
        "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/GoverningMembers/ramana-kumar.jpg",
    },

    /*
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },
        {
            "employe_name": "G. VINOD",
            "designation": "Ex-Minister",
            "description": "Combined State of A P",
            "image_path":"../../Asets"
        },*/
  ];

  const breadcrumbsPaths = [
    { label: "Home", link: "/" },
    { label: "About us" }
  ]; 

  return (
    <div style={{backgroundColor:"#DFE5F0"}}>
      <div  className="container breadcrumb" style={{ position: "relative", height: "45px"}} >
        <CustomBreadcrumbs  style={{ fontSize:"30px", fontWeight: "600" }}  paths={breadcrumbsPaths} />
        </div>

   
       <HCarosel />
      <section className="container">
      <div className="About_heading container">
        <div className="heading d-flex justify-content-center text-center">
          <sub>
            <img src={Qoute_icon} alt ="Qoute_Icon"/>
          </sub>
          <Typography variant="h3"><strong>Education is the key to a better future.</strong></Typography>
        </div>
      </div>
      <div className="container py-5">
        <div>
          <Grid container>
            <Grid
              item
              xs={5}
              md={6}
              className="d-flex flex-column align-items-center"
            >
              <div className="Kaka_Div">
                <img
                  src={KakaPhoto}
                  className="img-fluid kaka_Image"
                  alt="Kaka"
                />
              </div>
              <div className="text-center mt-3">
                <h4 className="m-0">Late Sri G Venkat Swamy</h4>
                <small>Founder and Chairman</small>
              </div>
            </Grid>

            <Grid item xs={5} md={5}>
              <div className="px-10">
                <div>
                  <Typography variant="h4">About The Founder</Typography>
                </div>
                <div>
                  <Typography variant="body1">
                    Born on October 5, 1929, the late Sri G Venkat Swamy,
                    Founder and Chairman of Dr. B. R. Ambedkar Educational
                    Institutions was the visionary behind setting up of these
                    group of establishments. With an analytical mind, infinite
                    capacity for work, and a knack for human relations, he also
                    functioned as a veteran Congress leader and Ex-Union
                    minister for the Government of India.<br></br>
                    Popularly known as ‘Kaka’, his political career began in
                    1950 and spanned five decades. Kaka was a four-time Member
                    of Parliament and served his last term in 2004. One who
                    worked towards improving living conditions of the weaker
                    sections of the society, he was also the General Secretary
                    of the National Huts Union in Hyderabad, providing permanent
                    accommodation for 75,000 hut dwellers.
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="background-lightblue">
        <div className="container py-5">
          <div>
            <Grid container>
              <Grid item xs={10} md={6}>
                <div SX={{ borderRadious: "8px" }}>
                  <img
                    src={
                      "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/homepage slider/ArialView.jpeg"
                    }
                    width="100%"
                    className="img-fluid rounded"
                    alt="Banner1"
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="px-4">
                  <div>
                    <h4>Started In 1973</h4>
                  </div>
                  <div>
                    <p>
                    Dr. B. R. Ambedkar Law College in Hyderabad  is a renowned institution that has been empowering students with legal education for decades. With a focus on social justice and equality, the college has produced numerous accomplished legal professionals who have  made a signiﬁcant impact on society. This presentation will delve into the rich history and contributions of Dr. B. R. Ambedkar Law College. Dr B R Ambedkar Law College, an integral part of Dr B R Ambedkar Educational Institutions was established in the year 1991, by late G. Venkataswamy Garu, the then Member of Parliament and Deputy Floor leader during 2000-2001. He was also a visionary for a progressive society. It is a moment of pride for our college for having completed glorious 32 years of establishment. Imparting legal education was the most cherished dream of our founder Chairman Late.Sri G. Venkat Swamy Garu. Since its establishment in 1991, Dr. B R Ambedkar Law College has been successful in producing legal luminaries, bureaucrats & judges.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div>
            <div>
            <Typography variant="h4" textAlign="center">Campus</Typography>
            </div>
            <div>
              <p>
                The campus is spread across 3 acres landscape known for its
                salubrious climate and presents a congenial atmosphere to pursue
                higher studies. Our vibrant infrastructure is a facilitator for
                the effective delivery of our curriculum. Apart from the common
                central facilities, the colleges have well-equipped
                laboratories, lecture halls and seminar halls.
              </p>
              {/* <div className="position-relative pt-2 pb-5">
              <div className="position-absolute top-100 start-50 translate-middle mt-5 pt-5">
                <img
                  src={
                    "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/banners/LawCollege.jpg"
                  }
                  style={{ width: "100%", height: "225px" }}
                  alt="Law_Collage"
                />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pt-5 mt-5">
        <Achived />
      </div> */}
      {/* <div className="container">
        <div className="my-5">
          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <img src={banner_img} className="w-100" alt="Law_Collage" />
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="px-4">
                <div className="my-3">
                  <img src={Qoute_icon} width={70} alt="Qoute Icon" />
                </div>
                <div>
                  <p>
                    Born on October 5, 1929, the late Sri G Venkat Swamy,
                    Founder and Chairman of Dr. B. R. Ambedkar Educational
                    Institutions was the visionary behind setting up of these
                    group of establishments. With an analytical mind, infinite
                    capacity for work, and a knack for human relations, he also
                    functioned as a veteran Congress leader and Ex-Union
                    minister for the Government of India.
                  </p>
                  <p>
                    Popularly known as ‘Kaka’, his political career began in
                    1950 and spanned five decades. Kaka was a four-time Member
                    of Parliament and served his last term in 2004. One who
                    worked towards improving living conditions of the weaker
                    sections of the society, he was also the General Secretary
                    of the National Huts Union in Hyderabad, providing permanent
                    accommodation for 75,000 hut dwellers.
                  </p>
                  <p>
                    Started in 1973, Dr B R Ambedkar Educational Society was
                    inaugurated by the then Hon. President of India late Sri V.
                    V. Giri. The vision behind the setting up of the group of
                    institutions is deeply rooted in Kaka’s beliefs in Dr Bhim
                    Rao Ambedkar’s ideologies. The establishment was later
                    converted to a Public Education Trust that aims to provide
                    non-commercial, quality education to the underprivileged
                    children, particularly to those from communities deemed as
                    minorities by the Government of India. He was associated
                    with the Trust as Chairman until the leader breathed his
                    last.
                  </p>
                  <p>
                    Till date, more than 1,00,000 students are the benefactors
                    of these institutions, who now serve the nation in valued
                    capacities like the administrative, law enforcement, health,
                    engineering and politics, among others. His dreams are much
                    cherished and continue to propel us to realise the goals of
                    our organisation.
                  </p>
                </div>
                <div className="mt-3">
                  <h6 className="m-0">Sri G Venkat Swamy</h6>
                  <small>Founder and Chairman</small>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        </div>
      </div> */}
      <div className="container">
        <Typography variant="h4" className="py-3" textAlign="center">Meet the Founders</Typography>
          <Grid container>
            {employee_Data &&
              employee_Data.map((items, index) => {
                return (
                  <Grid item xs={12} md={3} key={index}>
                    <div className="p-2">
                      <div className="employee_img">
                        <img  className="employee_img" src={items.image_path} width={250} height={320} alt="Founder data"/>
                      </div>
                      <Typography component="div" className="mt-2">
                      <Typography variant="h6">{items.employe_name}</Typography>
                        <Typography component="div">
                          <Typography variant="body1" className="m-0 fs-15">{items.designation}</Typography>
                          <Typography variant="body1" className="m-0 fs-15">{items.description}</Typography>
                        </Typography>
                      </Typography>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        
      </div>
    </section>
    </div>
  );
};

export default About_us;
