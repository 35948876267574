import React from "react";
import { Breadcrumbs, Link, Typography, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const CustomBreadcrumbs = ({ paths }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Breadcrumbs aria-label="breadcrumb" separator=">" style={{ fontSize: "18px",paddingTop:"15px", fontWeight: "600" }}>
        {paths.map((path, index) => (
          <span key={index}>
            {path.link ? (
              <Link color="rgb(33,53,100)" component={RouterLink} to={path.link}>
                {path.label}
              </Link>
            ) : (
              <Typography component="primary" color="rgb(223,82,38)">{path.label}</Typography>
            )}
          </span>
        ))}
      </Breadcrumbs>
    </Box>
  );
};

export default CustomBreadcrumbs;