import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  CardActionArea,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  CardContent,
  Card,
} from "@mui/material";
import ExpandableText from "../../Pages/HS/ExpandableText";
import Black_Arrow from "../../Assets/Icons/Black_Arrow.svg";
import ImageCarosel from "../../Component/Carosel/Image_Carosel";

const CardEvent = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openDialog = (index) => {
    setSelectedEventIndex(index);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setSelectedEventIndex(null);
    setDialogOpen(false);
    setShowFullDescription(false);
  };

  const eventData = props.getdata[0];

  const navigateToActivitiesPage = () => {
    const { pathname } = location;
    const isNotHome = pathname === "/home";
    const path = isNotHome ? "/activity" : `${pathname}/subject-activities`;
    navigate(path);
  };
  return (
    <section>
      <div className="text-end">
        <Button
          size="large"
          sx={{ color: "rgb(223,82,38)" }}
          onClick={() => navigateToActivitiesPage(eventData.department)}
        >
          See All
        </Button>
      </div>
      <Grid container spacing={4} className="my-5 w-200">
        <Grid item xs={12} md={6} className="px-6">
          <ImageCarosel
            image_data={
              props.getdata[0].image && props.getdata[0].image.length > 0
                ? props.getdata[0].image
                : [
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png",
                  ]
            }
            onClick={() => openDialog(0)} // Pass openDialog function as onClick handler
            alt={`card_image_0`}
          />
        </Grid>
        <Grid item xs={12} md={6} className="pl-3 d-flex align-items-center">
          <div className="d-block">
            <Typography component="div">
              <Typography variant="h6">{props.getdata[0].title}</Typography>
              <ExpandableText
                text={props.getdata[0].description}
                initialChars={500}
                showFullText={false}
              />
            </Typography>
            <div className="d-flex align-items-center justify-content-between">
              <Button size="small">{props.getdata[0].event_date}</Button>
              <img src={Black_Arrow} alt="BlackArrow" />
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {props.getdata &&
          props.getdata.slice(1, 4).map((items, index) => (
            <Grid item key={index} xs={12} md={4}>
              <Card className="ECard mx-2">
                <CardActionArea>
                <ImageCarosel
            image_data={items.image}
            onClick={() => openDialog(0)} // Pass openDialog function as onClick handler
            alt={`card_image_0`}
          />
                  {/* <img
                    height="354"
                    src={
                      items.image && items.image[0] && items.image.length > 0
                        ? items.image[0]
                        : "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                    }
                    alt={`card_image_${index + 1}`}
                    className="card_image_A"
                    onClick={() => openDialog(index + 1)}
                  /> */}
                  <CardContent>
                    <Typography
                      className="m-0 text-truncate"
                      gutterBottom
                      variant="h6"
                      component="div"
                    >
                      {items.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="multine-ellipsis hide_description"
                    >
                      {items.description}
                    </Typography>
                  </CardContent>
                  <Dialog
                    open={dialogOpen && selectedEventIndex === index + 1}
                    onClose={closeDialog}
                    maxWidth="sm"
                    fullWidth
                  >
                    <DialogTitle>{items.title}</DialogTitle>
                    <DialogContent>
                      {dialogOpen && selectedEventIndex === index + 1 && (
                        <>
                          <Typography component="div">
                            <ExpandableText
                              text={items.description}
                              initialChars={100}
                              showFullText={false}
                            />
                          </Typography>
                          <ImageCarosel image_data={items.image} />
                        </>
                      )}
                      {/* <img
                        src={
                          items.image && items.image[0] && items.image.length > 0
                            ? items.image[0]
                            : "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                        }
                        alt={`card_image_${index + 1}`}
                        className="mx-auto d-block cursor-pointer w-100 my-1"
                  
                      /> */}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={closeDialog} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <CardActions className="d-flex align-items-center justify-content-between">
                    <Button size="small">{items.event_date}</Button>
                    <img src={Black_Arrow} alt="BlackArrow" />
                  </CardActions>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </section>
  );
};

export default CardEvent;
