import { Card, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
// import CardSocialMedia from "../../Component/Cards/CardSocialMedia";
// import Servicescarosel from "../../Component/Carosel/Services_Carosel";
import MissionVission from "../MV/MV";
import LawcourseData from "../Department/Lawcourse";
import PeopleIcon from "../../Assets/Icons/People_icon.svg";
import { CardActionArea, CardContent, Button, Box } from "@mui/material";
import HCarosel from "../../Component/Carosel/Carosel";
import CourseOfferedcarosel from "../../Component/Carosel/Department_Carosel";
import { useLocation } from "react-router-dom";
import College_Data from "../../Assets/JSON/MV.json";
import {
  useComponentDidMount,
  useComponentDidUpdate,
} from "../../Component/utils";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const CourseOffered = (props) => {
  const location = useLocation();
  const College_DATA = location?.state?.College_details?.CourseOffered;
  const [Selected_College_Data, setSelected_College_Data] = useState({});
  const [boxWidth, setBoxWidth] = useState("50%");

  const PBData = {
    PB: [
      {
        tittle: "DR. B. R. Ambedkar",
        disgnation: "President",
        image: "https://",
        discreption:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        disgnation: "President",
        image: "https://",
        discreption:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        disgnation: "President",
        image: "https://",
        discreption:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
      {
        tittle: "DR. B. R. Ambedkar",
        disgnation: "President",
        image: "https://",
        discreption:
          "Lorem ipsum dolor sit amet consectetur. Augue magna amet cursus blandit aliquet scelerisque nibh faucibus. Fusce imperdiet diam orci ame.",
      },
    ],
  };

  PBData.PB[0].image = require("../../Assets/Images/Rectangle 40.png");
  PBData.PB[1].image = require("../../Assets/Images/Rectangle 42.png");
  PBData.PB[2].image = require("../../Assets/Images/Rectangle 44.png");
  PBData.PB[3].image = require("../../Assets/Images/Rectangle 44.png");

  useComponentDidMount(() => {
    Get_College_Data();
  });
  useComponentDidUpdate(() => {
    Get_College_Data();
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const boxOffsetTop = document.getElementById("custom-box").offsetTop;
      const boxHeight = document.getElementById("custom-box").offsetHeight;

      if (
        scrollPosition + windowHeight / 1.2 >= boxOffsetTop &&
        scrollPosition + windowHeight / 2.5 <= boxOffsetTop + boxHeight
      ) {
        setBoxWidth("100%");
      } else {
        setBoxWidth("50%");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    window.open(Selected_College_Data?.SyllabusDoc?.courseDocument, "_blank");
  };

  const Get_College_Data = () => {
    let selected_College_data =
      College_Data.College_CourseOffered_Details?.find(
        (items) => items.CourseOffered === College_DATA?.title
      );
    setSelected_College_Data(selected_College_data);
    console.log("Data: ", selected_College_data);
  };

  return (
    <section>
      {Selected_College_Data === undefined ? (
        <div className="m-5 py-5 text-center">
          <h1>NO DATA FOUND</h1>
        </div>
      ) : (
        <div>
           
          <div className="position-relative">
            <div>
            <HCarosel />
            </div>
            <div className="position-absolute bottom-0 start-0 ms-4 text-white mb-4">
              <h4 className="fs-1">Welcome to {College_DATA?.title}</h4>
            </div>
          </div>
          <div>
            <div className="my-5">
              <div className="container">
                <Grid
                  sx={{ justifyContent: "space-around" }}
                  container
                  spacing={2}
                >
                  <Grid container center xs={12} md={8}>
                    <Grid item xs={12} md={2} className="px-3">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="text-center">
                          <CardContent>
                            <img src={PeopleIcon} alt="" />
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={5} className="px-3">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="d-block">
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            Student Strength
                          </Typography>
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h4"
                            component="div"
                          >
                            {Selected_College_Data?.Seats_Allotted}
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={5} className="px-3">
                      <Card className="background-blue height-110 d-flex justify-content-center align-items-center">
                        <CardActionArea className="d-block">
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            <span className="m-0 fs-2">
                              {Selected_College_Data?.Seats_Allotted}
                            </span>
                            Student
                          </Typography>
                          <Typography
                            className="m-0 text-white text-center"
                            gutterBottom
                            variant="h6"
                            component="div"
                          >
                            every Year
                          </Typography>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                  {/* <Grid container xs={12} md={4}>
                                        <Grid item xs={12} md={4}></Grid>
                                        <Grid item xs={12} md={8}>
                                            <Card className='background-orange height-110 d-flex justify-content-center align-items-center'>
                                                <CardActionArea className='d-block' onClick={navigateto}>
                                                    <Typography className="m-0 text-white text-center" gutterBottom variant="h6" component="div">
                                                        Check Course Details
                                                    </Typography>
                                                    <Typography className="m-0 text-white text-center" gutterBottom variant="h6" component="div">
                                                        <img className="text-white" src={Arrow_icon} />
                                                    </Typography>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    </Grid> */}
                </Grid>
              </div>
            </div>
            <div>
              <CourseOfferedcarosel />
            </div>
            {Selected_College_Data?.MissionVission &&
              Selected_College_Data?.MissionVission?.Mission.discreption !==
                "" && (
                <div>
                  <MissionVission
                    MV_DATA={Selected_College_Data?.MissionVission}
                  />
                </div>
              )}

            <section
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
              }}
              className="container w-100 py-2"
            >
              <Box
                id="custom-box"
                className="flexBox"
                sx={{
                  width: boxWidth,
                  transition: "width 0.7s ease",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    padding: "0px 0px 0px 30px",
                  }}
                >
                  Syllabus:
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    padding: "0px 0px 0px 30px",
                  }}
                >
                  {Selected_College_Data?.SyllabusDoc?.DocumentTitle}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    padding: "0px 30px 0px 0px",
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleButtonClick}
                    className="background-button"
                    endIcon={<FileDownloadIcon />}
                  >
                    Download
                  </Button>
                </Typography>
              </Box>
            </section>
            <div>
              <LawcourseData law_data={Selected_College_Data} />
            </div>

            {/* } */}
            {/* {Selected_College_Data?.Service_Data && (
              <div>
                <Servicescarosel />
              </div>
            )}
            {Selected_College_Data?.Social_media && (
              <div>
                <CardSocialMedia getdata={PBData} />
              </div>
            )} */}
          </div>
        </div>
      )}
    </section>
  );
};

export default CourseOffered;
