import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Grid, Button } from "@mui/material";
import "../../Styles/placements.css";

const PlacementProcess = ({ PlacementProcess }) => {
  return (
    <section className="process-grid-section" style={{}}>
      <div style={{ margin: "27px", paddingTop: "19px" }}>
        <Typography component="h1" sx={{ fontSize: "1.5rem" }}>
          {PlacementProcess.Title}
        </Typography>
        <Typography component="p" sx={{ fontSize: "0.9rem" }}>
          {PlacementProcess.Subtitle}
        </Typography>
        <Typography component="p" sx={{ fontSize: "1rem", paddingTop: "40px" }}>
          {PlacementProcess.description}
        </Typography>
      </div>

      <Grid className="Process_container" spacing={1}>
        {PlacementProcess.Placement_Approach.map((items, i) => (
          <Grid
            key={i}
            className="process_grid"
            item
            xs={4}
            sx={{ margin: "20px" }}
          >
            <div>
              <Typography
                component="img"
                src={items.logoImage}
                alt={`Company Logo ${i}`}
                sx={{ height: "32px", width: "33px" }}
              ></Typography>
              <Typography component="h4" sx={{ fontSize: "15px", fontWeight:"600" }}>
                {items.title}
              </Typography>
              <Typography
                component="p"
                sx={{ fontSize: "15px", fontWeight:"100" }}
              >
                {items.description}
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      <div style={{ marginBottom: "30px" }}>
        <Button variant="contained" sx={{ backgroundColor: "#f15215" }}>
          Check Out Full Detail
        </Button>
      </div>
    </section>
  );
};

export default PlacementProcess;
