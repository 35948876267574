import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Typography } from '@mui/material';
import '../../Styles/placements.css';  // Import the external CSS file

const CompanyList = ({ logosImages, title }) => {
  const settings = {
    infinite: true,
    speed: 4000,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    centerPadding: '0',
  };

  return (
    <section className="CompanyListSection">
      <Typography component="h1" className="CompanyListTitle">{title}</Typography>
      <div className="CompanyListSlider">
        <Slider {...settings}>
          {logosImages?.LogoImages.map((logo, index) => (
            <div key={index} className="CompanyListItem">
              <Typography component="img" src={logo.Image} alt={`Logo ${index}`} className="CompanyListLogo" />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default CompanyList;