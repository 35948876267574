import React, { useState, useEffect } from "react";
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router
} from "react-router-dom";
import AppRoutes from './Routes.js';
import Loader from "./Loader.js";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif',
    ].join(','),
    h4: {
      fontWeight: '500',
    },
    h5: {
      fontWeight: '600',
    }
  },
});

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
       <ThemeProvider theme={theme}>
        {loading ? <Loader /> : <AppRoutes />}
        </ThemeProvider>
    </Router>
  );
}

export default App;
