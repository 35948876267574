import React from "react";
import HCarosel from "../../Component/Carosel/Carosel";
import MissionVission from "../MV/MV";
import PlacementsCards from "../../Component/Placements_component/placementscards.js";
import Train_process_image from "../../Assets/Images/training_process.jpg";
import {Button} from "@mui/material";
import Events from "../Events/Events";
import Placements_data from "../../Assets/JSON/Placementjson/Placements.json";
import CompanyList from "../../Component/Placements_component/companylist.js";
import PlacementProcess from "../../Component/Placements_component/placementprocess.js";
// import GoalsAndObjectives from "../../Component/Placements_component/GoalsAndObjectives.js";
import StudentPlacementPage from "../../Component/Placements_component/StudentPlacementPage.js";

const placements = () => {

  const MV_Data = {
        "Mission": {
        "tittle": "Mission",
        "image": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/mission/mission.jpg",
        "description": "In Pursuit of excellence in education. To install a zeal and passion to Educate, Organize and Struggle. To ensure bright future with confidence."
      },
      "Vission": {
        "tittle": "Vision",
        "image": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/home/vision/vision.jpg",
        "description": "To empower the socially, economically, educationally backward sections of the society through education, training and self-employment."
      }
    }

  return (
    <section>
      <HCarosel />
      <section className="container my-5">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PlacementsCards />
        </div>
        {/* <Yearwise */}
        <MissionVission MV_DATA={MV_Data} />
        {/* <GoalsAndObjectives/> */}
        <div>
          <h2>About Training & Placement Cell</h2>
          <p>
            The Training and Placement cell plays a vital role in reducing the
            gap between the Campus and Corporate. It is the dream of every
            student to get placed in a top organization visiting their campus
            for recruitment. The Cell operates round the year to facilitate
            contacts between Hiring Companies and Graduates. The objective of
            Training & Placement Cell is to place all eligible students in
            reputed IT and core manufacturing, infrastructure companies right at
            the campus itself. To achieve this objective the cell liaises with
            the various industrial establishments, corporate houses which
            conduct campus interviews and select students from all disciplines,
            by identifying their knowledge skills, attitude matrices of every
            individual student, creating job profiles for them, identifying
            areas of training & various methods as per the training requirement.
          </p>
        </div>
        <div className="Training_process">
          <h2>Training Process</h2>
          <div>
            <p>
              The objective of the training department is to match the corporate
              expectations by providing them Soft skills, Aptitude & Technical
              training. The effectiveness of these training is evident from the
              consistent placement results. Several companies have been visiting
              the college every year to tap and recruit the young talented
              students. Guest lectures & Mock interview sessions are being
              organized with the help of industry experts to bridge the gap
              between industry & academia.
            </p>
            <div>
              <img className="Training_img" alt="Training Process" src={Train_process_image} />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: "#F15215", width: "15%" }}
            >
              Know More
            </Button>
          </div>
        </div>
        <Events Event_DATA={Placements_data?.Events_Details?.Training_Teams?.slice(0, 4)} title={Placements_data?.Events_Details?.Title}/>
        <div style={{ display: "block", position: "relative", marginBottom: "150px" }}>
  <CompanyList logosImages={Placements_data?.products} title={Placements_data?.products?.Title} />
</div>
        <Events Event_DATA={Placements_data?.Training_Events?.Training_Teams?.slice(0, 4)} title={Placements_data?.Training_Events?.Title}/>
        <PlacementProcess PlacementProcess={Placements_data?.Placement_Process}/>
        <Events Event_DATA={Placements_data?.Placement_Events?.Placement_activities?.slice(0, 4)} title={Placements_data?.Placement_Events?.Title}/>
        <StudentPlacementPage placedStudents = {Placements_data?.placedStudents_list}/>
      </section>
    </section>
  );
};

export default placements;
