import React from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MANDATORY_Doc_data from "../../Assets/JSON/IQAC/Mandatory.json";

const MANDATORY_Doc = () => {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const navigation_url = (path) => {
    if (path.pathname === "") {
      console.log("Pathname is empty, cannot navigate.");
      setOpen(true);
    } else {
      navigate("/mandatorydoc/" + path.id + "/" + path.pathurl);
    }
  };
  return (
    <section className="container py-4">
      <>
        <div>
          <Typography variant="h6" component="div" sx={{ my: 2 }}>
            {MANDATORY_Doc_data.title}
          </Typography>
        </div>
        <div>
          <p>{MANDATORY_Doc_data.description}</p>
        </div>
        <div>
          <Snackbar
            open={open}
            autoHideDuration={1000}
            onClose={handleClose}
            message="No Data Found"
          />
          {MANDATORY_Doc_data.documents.map((item) => (
            <div className="my-4" key={item.title}>
              <Typography variant="h6" component="div" sx={{ my: 2 }}>
                {item.title}
              </Typography>
              <div>
                <small
                  className="cursor-pointer"
                  onClick={() => {
                    navigation_url(item);
                  }}
                >
                  {item.filename}
                </small>
              </div>
            </div>
          ))}
        </div>
      </>
    </section>
  );
};

export default MANDATORY_Doc;
