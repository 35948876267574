import React, { useState } from "react";
import {
  Grid,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import CustomBreadcrumbs from "../../Component/CustomBreadcrumbs";
import placedStudents from "../../Assets/JSON/Placementjson/Placements.json";
import placedDropdown from "../../Assets/JSON/Placementjson/Placement_dropdown.json";
import "../../Styles/placements.css";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const AllStudentsPage = () => {
  const [selectedYear, setSelectedYear] = useState("Latest");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleImageClick = (student) => {
    setSelectedImage(student);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const breadcrumbsPaths = [
    { label: "Over View", link: "/placements" },
    { label: "Placements" },
  ];

  const filteredStudents =
    selectedYear === "Latest"
      ? placedStudents.placedStudents_list.images
      : placedStudents.placedStudents_list.images.filter(
          (student) => student.academicYear === selectedYear
        );

  return (
    <section>
      <Typography
        component="div"
        container
        sx={{ height: "70px", position: "relative", mx: 10 }}
      >
        <CustomBreadcrumbs paths={breadcrumbsPaths} />
      </Typography>
      <div>
        <Typography
          variant="h5"
          container
          justifyContent="right"
          sx={{
            fontSize: "2rem",
            mx: 15,
          }}
        >
          {placedStudents.placedStudents_list.Title}
        </Typography>
        <Grid container justifyContent="right" alignItems="center">
          <FormControl variant="standard" sx={{ mx: 15, my: 2, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">
              Academic Year
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedYear}
              onChange={handleChange}
              label="Academic Year"
            >
              <MenuItem value="Latest">Latest</MenuItem>
              {placedDropdown.Academic_Year.map((item) => (
                <MenuItem key={item.ID} value={item.Year}>
                  {item.Year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </div>

      <Grid container spacing={3} justifyContent="center">
        {filteredStudents.map((student, index) => (
          <Grid item key={index}>
            <img
              src={student.url}
              alt={student.alt}
              style={{
                height: "302px",
                width: "208px",
                borderRadius: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(student)}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedImage?.alt}</DialogTitle>
        <DialogContent>
          <img
            src={selectedImage?.url}
            alt={selectedImage?.alt}
            style={{ width: "100%" }}
          />
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogContent>
      </Dialog>
    </section>
  );
};

export default AllStudentsPage;
