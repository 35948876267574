import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MANDATORY_Doc_data from "../../Assets/JSON/IQAC/Mandatory.json";
import { Button, Typography } from "@mui/material";

const PDFViewer = () => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const filteredData = MANDATORY_Doc_data.documents.find((docdata) => docdata.id === id);
    setSelectedDocData(filteredData);
  }, [id]);

  const handleBackClick = () => {
    navigate('/mandatorydoc');
  };

  return (
    <div className="container py-4">
      <Button variant="contained" onClick={handleBackClick}>Go Back</Button>
      {selectedDocData && (
       <div>
       <Typography variant="h5" className="text-center">{selectedDocData.filename}</Typography>
       <iframe
         src={selectedDocData.pathname}
         title="PDF Viewer"
         width="100%"
         height="600px"
         style={{ border: 'none' }}
       />
       </div>
      )}
      {!selectedDocData && (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default PDFViewer;