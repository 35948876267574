import React, { Component } from "react";
import Slider from "react-slick";
// import image from "../../Assets/Images/Rectangle 220.png";
import { Typography, Button } from '@mui/material';

export default class Department_Carousel extends Component {
  render() {
    const settings = {
      arrows: false,
      // autoplay: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1
    };
const image = "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/departments/Banners/law-1012473.jpg"

    return (
      <div>
        <Slider {...settings}>
          <div>
            <div className="position-relative">
              <div>
                <Typography component="img" src={image} height={400} className="w-100" />
              </div>
              <div className="position-absolute top-0 start-0 ms-5 text-white mt-5">
                <div className="">
                  <h1 style={{ fontSize: '40px' }}>Future Events Banner</h1>
                  <Button variant="contained" className="Header_Img_Btn m-3 mx-5">Apply for Admission</Button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img src={image} height={400} className="w-100" />
            <div className="">
              <div className="Header_Img_Heading">
                <h1 style={{ fontSize: '40px' }}>The Secret of Getting Ahead Is <span><i>Getting Started</i></span></h1>
                <Button variant="contained" className="Header_Img_Btn m-3 mx-5">Apply for Admission</Button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
