import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import _set from "lodash/set";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _keys from "lodash/keys";
import { Link } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import SubmittedImage from "../../Assets/Images/FeedbackSubmitted.png";
import Axios from "axios";
import "../../Styles/Feedback.css";
import { ToastContainer, toast } from "react-toastify";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import { fetchData, postData } from "../../Assets/API/apiservices";

const Feedback = () => {
  const [selectedTab, setSelectedTab] = useState(null);
  const [feedBackData, setFeedBackData] = useState("");
  const [activeButton, setActiveButton] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [feedbackTabData, setFeedbackTabData] = useState([]);
  const [feedbackFormData, setFeedbackFormData] = useState(null);
  const [formID, setFormID] = useState(null);
  const [error, setError] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [formData, setFormData] = useState(feedBackData);

  useEffect(() => {
    getData();
  }, []);

  const validateForm = (tabIndex) => {
    const currentTabData = feedbackFormData.tabs[tabIndex];
    const errors = {};
    _keys(currentTabData).forEach((key) => {
      const field = currentTabData[key];
      const fieldValue = String(
        _get(feedbackFormData, `tabs.${tabIndex}.${key}`, "")
      ).trim();

      if (field.required && _isEmpty(fieldValue)) {
        errors[key] = `${field.label || key} is required.`;
      }
    });

    setFieldErrors(errors);
    return _isEmpty(errors); // Return if form is valid or not
  };
  

  // useEffect(() => {
  //   validateForm();
  // }, [feedBackData, selectedTab]);

  const handleNextOrSubmit = (event) => {
    event.preventDefault();
    const formIsValid = validateForm(selectedTab);
    
    if (formIsValid) {
      if (selectedTab === "Suggestions") {
        onSubmit();  // Submit the final tab
      } else {
        const nextTabIndex = _keys(feedbackFormData.tabs).indexOf(selectedTab) + 1;
        if (nextTabIndex < _keys(feedbackFormData.tabs).length) {
          setSelectedTab(_keys(feedbackFormData.tabs)[nextTabIndex]);
        }
      }
    } else {
      toast.error("Please fill in all required fields before proceeding.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  };
  useEffect(() => {
    if (submitted) {
      window.scrollTo({ top: 0, behavior:'auto'});
    }
  }, [submitted]);

  const getData = async () => {
    try {
      const result = await fetchData("feedbackformtypes");
      setFeedbackTabData(result.rows);
      if (result.rows.length > 0) {
        setFormID(result.rows[0].feedback_form_type_id);
      }
    } catch (err) {
      setError(err.message);
      toast.error(err.message);
    }
  };

  const getFormData = async (id) => {
    try {
      const result = await fetchData(`feedbackformtypes/${id}`);
      setFormData(result) 
      setFeedbackFormData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (formID) {
      getFormData(formID);
    }
  }, [formID]);

  const onChangeEvent = (path, event) => {
    const newValue = event.target.value;
    _set(feedbackFormData, path, newValue);
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSelectedTab(null);
    setFormID(buttonId);
    setFeedBackData(""); 
    setFeedbackFormData(null); 
    setFormData(null);
    validateForm();
  };
  

  const yesNoRadioButton = (e, key, path) => {
    _set(feedbackFormData, path, key);
    setFeedBackData({ ...feedbackFormData });
  };
  
  const ratingRadioButton = (e, key,questionText,  path) => {
    _set(feedbackFormData, path, key);
    setFeedBackData({ ...feedbackFormData });
  };
  const onSubmit = async () => {
      const modifiedFeedbackData = {
        ...feedbackFormData,
        organization_name: 2,
      };

    try {
        await postData("feedbackresponses", modifiedFeedbackData, {
            timeout: 5000,
            headers: { "Content-Type": "application/json" },
        });
        setFeedBackData("");
        setSelectedTab("");
        setSubmitted(true);
    } catch (error) {
        console.error("Error submitting data:", error);
        toast.error("Error: Something Went Wrong..!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
        });
    }
};
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isFormValid) {
      toast.error("Please fill in all required fields.", {
        position: "top-right",
        autoClose: 5000,
      });
      return;
    }
  };
  const onSub = (dta) => {
    setSelectedTab(dta);
    console.log("Result: ", dta);
  };

  const YesNo_Question = (YesNo_Data, path) => {
    console.log("YesNo_Data", YesNo_Data);
    const { questions = [], options = [] } = YesNo_Data || {};
    return (
      <div className="d-flex flex-column">
        {questions.map((YesNoQuestion, index) => (
          <div key={index} className="mb-3 color-blue">
            <p>{YesNoQuestion.question_text}</p>
            <div>
              {options.map((option, optionIndex) => (
                <FormControlLabel
                  key={`${index}_${option}`}
                  control={<Radio />}
                  label={option}
                  onChange={(e) =>
                    yesNoRadioButton(
                      e,
                      option,
                      `${path}.questions[${index}].answer_key`
                    )
                  }
                  checked={ _get(feedbackFormData, `${path}.questions[${index}].answer_key`) === option}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };
  const rating_data = (feedback_Data, path) => {
    console.log("feedback_Data", feedback_Data, path);

    return (
      <section className="feedback-table-container w-100">
        <Table aria-label="feedback table">
          <TableHead>
            <TableRow className="table-header" style={{ color: "white" }}>
              <TableCell
                className="vertical-text"
                style={{ minWidth: "15rem", width: "100% !important" }}
              >
                {feedback_Data.group_name}
              </TableCell>
              {feedback_Data.options?.map((option, index) => (
                <TableCell
                  key={index}
                  className="vertical-text"
                  style={{ width: "10%" }}
                >
                  {option}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback_Data?.questions?.map((row, questionIndex) => (
              <TableRow key={questionIndex} className="table-row">
                <TableCell
                  component="th"
                  scope="row"
                  key={questionIndex}
                  className="question-cell"
                  style={{ width: "50rem" }}
                >
                  {row.question_text}
                </TableCell>
                {feedback_Data.options?.map((option, optionIndex) => (
                  <TableCell
                    align="center"
                    key={`${questionIndex}_${optionIndex}`}
                    className="radio-cell"
                  >
                    <Radio
                      checked={_get(feedbackFormData, `${path}.questions[${questionIndex}].answer_key`) === option}
                      
                      onChange={(e) =>
                        ratingRadioButton(
                          e,
                          option,
                          row.question_text,
                          `${path}.questions[${questionIndex}].answer_key`
                        )
                      }
                      value={option.toString()}
                      name={`question-${row.question_id}`}
                      inputProps={{ "aria-label": option.toString() }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    );
  };

  const renderInput = (data, path) => {
    const label = data.name || data.personal_detail_name;
    const type = _get(data, "input_type", "");
    const SuggestionData = _get(data, "questions", data.answer_key);
    switch (type) {
      case "dropdown":
      case "drop":
        return (
          <div className="form-group">
            <label htmlFor={path} className="form-label">
              {label}
            </label>
            <select
              id={path}
              onChange={(e) => onChangeEvent(path, e)}
              value={data.answer_key}
              // className="custom-dropdown"
              className={`custom-dropdown form-control ${
                fieldErrors[path] ? "error" : ""
              }`}
            >
              <option value="">Please select an option</option>
              {
                data.options.map((option, i) => (
                   <option value={option} key={i}>
                    {option}
                  </option>
                )
              )
              }
            </select>
          </div>
        );
      case "text":
        return selectedTab === "Suggestions" ? (
          <div>
            {Array.isArray(SuggestionData) ? (
              SuggestionData.map((suggestion, index) => {
                  let newPath = path.replace('.answer_key', `.questions[${index}].answer_key`);
                return (
                  <div key={index} className="form-group">
                    <label htmlFor={path} className="form-label">
                      {suggestion.question_text}
                    </label>
                    <input
                      type="text"
                      id={path}
                      onChange={(e) => handleInputChange(newPath, e.target.value)}
                      // value={ data.answer_key}
                      className="form-control"
                    />
                  </div>
                );
              })
            ) : (
              <div>No Suggestions Available</div>
            )}
          </div>
        ) : (
          <div className="form-group">
            <label htmlFor={path} className="form-label">
              {label}
            </label>
            <input
              type="text"
              id={path}
              onChange={(e) => handleInputChange(path, e.target.value)}
              // value={ data.answer_key}
              className="form-control"
            />
          </div>
        );

      case "textarea":
        return (
          <div className="form-group">
            <label htmlFor={path} className="form-label">
              {label}
            </label>
            <textarea
              id={path}
              onChange={(e) => handleInputChange(path, data.question_id, e.target.value)}
              // value={ data.answer_key}
              rows={4}
              className="form-control"
            />
          </div>
        );

      case "radio":
        return (
          <div className="form-group">
            <label className="form-label">{label}</label>
            <div className="d-flex flex-column">
              {data.group_name === "null"
                ? YesNo_Question(data, path)
                : rating_data(data, path)}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleInputChange = (section, value) => {
    _set(feedbackFormData, section, value);
    console.log("handleInputChange==>", section);
    // console.log("feedback", feedBackData)
    // setFeedBackData({ ...feedBackData });
  };

  return (
    <section className="container py-5">
      <ToastContainer />
      <div className="feedBack_container row">
        {!submitted ? (
          <>
            <div className="col-sm-4 mx-3 mb-3">
              <div
                className="text-white p-3 rounded"
                style={{ backgroundColor: "#999BE5", textAlign: "center" }}
              >
                <h4 className="m-0">Feedback Forms</h4>
              </div>
              {feedbackTabData.map((item) => (
                <div
                  key={item.feedback_form_type_id}
                  className="border d-flex align-items-center my-2 rounded"
                >
                  <Button
                    variant="text"
                    style={{
                      width: "100%",
                      backgroundColor:
                        activeButton === item.feedback_form_type_id
                          ? "#6c757d"
                          : "#f8f9fa",
                      color:
                        activeButton === item.feedback_form_type_id
                          ? "white"
                          : "#007bff",
                      borderRadius: "0px",
                    }}
                    onClick={() =>
                      handleButtonClick(item.feedback_form_type_id)
                    }
                    className="p-3"
                  >
                    <b>{item.display}</b>
                  </Button>
                </div>
              ))}
            </div>

            {!_isEmpty(feedbackFormData) && (
              <div className=" column col-sm-8">
                <h4>{feedbackFormData.feedback_form_type}</h4>
                <form onSubmit={handleSubmit}>
                  <div className="row mb-4">
                    {feedbackFormData.headers &&
                      _keys(feedbackFormData.headers).map((key, i) => {
                        const header = feedbackFormData.headers[key];
                        
                        return (
                          <div key={i} className="col-sm-6 mb-3">
                            <div className="m-3">
                              {header && renderInput(header,
                                `headers.${key}.answer_key`
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </form>
                <div className="d-flex my-4">
                  {feedbackFormData.tabs &&
                    _keys(feedbackFormData.tabs).map((items, index) => (
                      <div className="mx-2" key={index}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            maxWidth: "200px",
                            height: "40px",
                            fontSize: "0.765rem",
                            backgroundColor:
                              items === selectedTab ? "#6c757d" : "#999BE5",
                            color: "white",
                            "&:hover": {
                              backgroundColor:
                                items === selectedTab ? "#6c757d" : "#0056b3",
                            },
                            "@media (max-width: 600px)": {
                              maxWidth: "150px",
                              height: "40px",
                              fontSize: "0.775rem",
                            },
                            "@media (max-width: 400px)": {
                              fontSize: "0.75rem",
                            },
                          }}
                          onClick={() => onSub(items)}
                        >
                          <Tooltip title={items.length > 10 ? items : ""} arrow>
                            <span>
                              {items.length > 11
                                ? `${items.slice(0, 11)}...`
                                : items}
                            </span>
                          </Tooltip>
                        </Button>
                      </div>
                    ))}
                </div>

                <div className="mb-4 p-4">
                  {feedbackFormData.tabs[selectedTab] ? (
                    <div className="row">
                      {feedbackFormData.tabs[selectedTab].map(
                        (tabKey, index) => {
                          const tabContent =
                            feedbackFormData.tabs[selectedTab][index];
                          const inputType = tabContent.input_type;

                          return (
                            <div
                              key={index}
                              className={`mb-3 ${
                                inputType === "radio" ? "col-12" : "col-sm-6"
                              }`}
                            >
                              {inputType === "radio" ? (
                                <div className="d-flex flex-column">
                                  {renderInput(
                                    tabContent,
                                    `tabs.${selectedTab}[${index}]`
                                  )}
                                </div>
                              ) : (
                                <div className="d-flex flex-row">
                                  {renderInput(
                                    tabContent,
                                    `tabs.${selectedTab}[${index}].answer_key`
                                  )}
                                </div>
                              )}
                            </div>
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <>
                      {feedbackFormData.tabs[selectedTab]?.input_type ===
                        "radio" && (
                        <div>
                          {feedbackFormData.tabs[selectedTab]?.options
                            ? YesNo_Question(
                                feedbackFormData.tabs[selectedTab],
                                `${activeButton}.tabs.${selectedTab}`
                              )
                            : feedbackFormData.tabs[selectedTab]?.options &&
                              rating_data(
                                feedbackFormData.tabs[selectedTab],
                                `${activeButton}.tabs.${selectedTab}`
                              )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-end">
                      <Button
                      className="px-5"
                       sx={{
                        backgroundColor:
                        selectedTab === "Suggestions"
                          ? "#28a745"
                          : "#0000F9",
                      color: "white",
                      "&:hover": {
                        backgroundColor:
                        selectedTab
                            ? "#218838"
                            : "#0056b3",
                      },
                      }}
                        variant="contained"
                        color="primary"
                        onClick={handleNextOrSubmit}
                      >
                        {selectedTab === "Suggestions" ? "Submit" : "Next"}
                      </Button>
                    </div>
                  </div>
            )}
          </>
        ) : (
          <div className="col-sm-12 text-center">
            <img
              src={SubmittedImage}
              alt="Feedback Submitted"
              className="img-fluid mb-4"
            />
            <Button
              variant="contained"
              component={Link}
              to="/"
              sx={{
                backgroundColor: "#007bff",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Go to Main
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Feedback;
