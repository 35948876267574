import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
//   Button,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const NaacuploadPdfViewer = ({
  data,
  dataKey = "ID",
  filenameKey = "filename",
  pathKey = "pathurl",
}) => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { conatiner,year,course,pathurl } = useParams();
  // const navigate = useNavigate();

//   const searchLevels = (levels, idToFind) => {
//     for (const key in levels) {
//       if (
//         ["level1", "level2", "level3"].includes(key) &&
//         Array.isArray(levels[key])
//       ) {
//         const item = levels[key].find((subDoc) => subDoc[dataKey] === idToFind);
//         if (item) return item;
//       } else if (typeof levels[key] === "object") {
//         const result = searchLevels(levels[key], idToFind);
//         if (result) return result;
//       }
//     }
//     return null;
//   };

useEffect(() => {
  // Construct the path conditionally based on year and course availability
  const baseUrl = "https://dbraeiassetmanagement.blob.core.windows.net/";
  let constructedPath = `${baseUrl}${conatiner}/`;

  if (year) {
    constructedPath += `${year}/`;
  }

  if (course) {
    constructedPath += `${course}/`;
  }

  constructedPath += pathurl;

  setSelectedDocData(constructedPath || null);
  console.log("Path URL:", constructedPath);
  setLoading(false);
}, [conatiner, year, course, pathurl]);

useEffect(() => {
  const timer = setTimeout(() => setLoading(false), 10000);
  return () => clearTimeout(timer);
}, [loading]);

if (!selectedDocData) {
  return (
    <Box textAlign="center" my={4}>
      <Typography variant="h6">No data found.</Typography>
    </Box>
  );
}

if (loading) {
  return (
    <Box textAlign="center" my={4}>
      <CircularProgress />
      <Typography variant="h6" mt={2}>
        Loading...
      </Typography>
    </Box>
  );
}

  return (
    <Box my={4} className="container">
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            {/* <Button
              variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button> */}
            <Typography variant="h5" textAlign="center"  flexGrow={1}>
              {selectedDocData
                ? pathurl
                : "No file available"}
            </Typography>
          </Box>
          <iframe
            src={selectedDocData || ""}
            title="PDF Viewer"
            width="100%"
            height="600px"
            style={{ border: "none" }}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default NaacuploadPdfViewer;
