import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PDFIcon from "../../Assets/Icons/PDF_icon.png";
import NIRFExData from "../../Assets/JSON/NAACjson/NIRFExtended_Data.json";
import "../../Styles/Naac.css";
import { useNavigate } from "react-router-dom";

const NIRFExtended = () => {
  const [expanded, setExpanded] = useState(0);
  const [selectedNIRFData, setSelectedNIRFData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const NIRFExtended_DATA = NIRFExData?.NIRFExtended_Headings;
  const NIRFSubheadingData = NIRFExData.NIRFExtended_Data;

  const handleChange = (panel, index) => (event, newExpanded) => {
    localStorage.setItem("expandedIndex", newExpanded ? panel : 1);
    setExpanded(newExpanded ? panel : false);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: theme.palette.mode === "dark" ? "#fff" : "black",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
      borderRadius: "50%",
    },
  }));

    const filterData = useCallback((selected) => {
    const filter_data = NIRFSubheadingData.filter(
      (NIRFdata) => NIRFdata.ID === selected.ID
    );
    setSelectedNIRFData(filter_data);
  }, [NIRFSubheadingData]);


  useEffect(() => {
    if (NIRFSubheadingData && NIRFSubheadingData.length > 0) {
      filterData(NIRFSubheadingData[0]);
    }
  
    return () => {
      isMounted.current = false;
    };
  }, [NIRFSubheadingData, filterData]);
  

  const handlePdfClick = (urldata) => {
    console.log("==>",urldata);
       
      navigate("/nirf/" + urldata.id + "/" + urldata.filename);
}

  return (
    <section className="container">
      <div>
        <Grid container>
          <Grid items xs={12} md={3}>
          {NIRFExtended_DATA &&
  NIRFExtended_DATA.map((items, parentIndex) => {
    const isExpanded = expanded === parentIndex;

    return (
      <Accordion
        key={parentIndex}
        expanded={isExpanded}
        onChange={handleChange(parentIndex)}
      >
        <AccordionSummary
          style={{
            backgroundColor: isExpanded ? "rgb(25, 47, 89)" : "#D0D0D0",
            color: isExpanded ? "white" : "inherit",
          }}
          onClick={() => {
            if (!isExpanded) {
              const firstSubheading = items.SubHeading[0];
              filterData(firstSubheading);
            }
          }}
          disabled={ items.isActive === false }
        >
          <Typography>{items.Heading}</Typography>
        </AccordionSummary>
        {items?.SubHeading &&
          items.SubHeading.map((subdata, i) => {
            return (
              <AccordionDetails
                key={i}
                onClick={() => filterData(subdata)}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "0.9rem",
                    margin: "auto",
                    position: "relative",
                    left: "15px"
                  },
                }}
                className={
                  selectedNIRFData.some(
                    (data) => data.ID === subdata.ID
                  )
                    ? "selected cursor-pointer"
                    : "AccordionSummary cursor-pointer"
                }
              >
                <Typography>{subdata.Subheading}</Typography>
              </AccordionDetails>
            );
          })}
      </Accordion>
    );
  })}

          </Grid>
          <Grid items xs={12} md={8} className="px-5">
            {selectedNIRFData[0]?.URLs ? (
              <div>
                {selectedNIRFData.map((HeadItem) =>
                  Object.entries(HeadItem.URLs).map(([key, value]) => (
                    <div key={key}>
                      <div>
                        <h4>{value.map((items) => items.Title)}</h4>
                      </div>
                      <div className="d-flex flex-wrap">
                        {value.map((urldata, index) => (
                          <div key={index} className="col-md-3 mb-3">
                            <div>
                              <img
                                src={PDFIcon}
                                onClick={() => handlePdfClick(urldata)}
                                alt="PDF Files"
                                className="cursor-pointer"
                              />
                            </div>
                            <div>
                              <p className="mt-1 text-center">
                                {urldata.filename}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div>
                <h4>No Data Selected</h4>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </section>
  );
};

export default NIRFExtended;
