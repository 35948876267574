import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import NIRFExData from "../../Assets/JSON/NAACjson/NIRFExtended_Data.json";

const Nirfpdf = () => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {

    let found = false;

    for (const docdata of NIRFExData.NIRFExtended_Data) {
      for (const key in docdata.URLs) {
        const urlDataArray = docdata.URLs[key];
        const filteredData = urlDataArray.find(urlData => urlData.id.toString() === id);
        if (filteredData) {
          setSelectedDocData(filteredData);
          found = true;
          break;
        }
      }
      if (found) break;
    }

    if (!found) {
      setSelectedDocData(null);
      console.log("No matching data found.");
    }
  }, [id]);

  const handleBackClick = () => {
    navigate('/nirf');
  };

  return (
    <div className="container py-4">
      <Button variant="contained" onClick={handleBackClick}>Go Back</Button>
      {selectedDocData ? (
        <div>
          <Typography variant="h5" className="text-center">{selectedDocData.filename}</Typography>
          <iframe
            src={selectedDocData.pathurl}
            title="PDF Viewer"
            width="100%"
            height="600px"
            style={{ border: 'none' }}
          />
        </div>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Nirfpdf;
