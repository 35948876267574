import axios from 'axios';
import { GLOBAL_DOMAIN } from '../../Config/Config';

export const fetchData = async (endpoint) => {
  try {
    const response = await axios.get(`${GLOBAL_DOMAIN}${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(`${GLOBAL_DOMAIN}${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

export const updateData = async (endpoint, data) => {
  try {
    const response = await axios.put(`${GLOBAL_DOMAIN}${endpoint}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating data:', error);
    throw error;
  }
};

// Example API call for deleting data
export const deleteData = async (endpoint) => {
  try {
    const response = await axios.delete(`${GLOBAL_DOMAIN}${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
