import { Typography } from '@mui/material';
import React from 'react';

const Lawcourse = (props) => {
    const lawData = props.law_data;
    const { courseTitle, text, content, objectiveTitle, programOutcome } = lawData?.Course_Data || {};

    return (
        <section className="container my-5 ">
            <Typography component="div" className="pb-20">
                <Typography variant='h5' style={{ paddingBottom:"8px" }}>{courseTitle}:</Typography>
                <Typography variant='body1'className="pb-10 body1_color">{text}</Typography>
            </Typography>
            {content && content.map((section, index) => (
                <Typography component="div" className="pb-20" key={index}>
                    <Typography variant='h5' sx={{ paddingBottom:"8px"}}>
                        {section.heading}
                    </Typography>
                    <Typography variant='body1' className="pb-10 body1_color">{section.text}</Typography>
                </Typography>
            ))}
            {objectiveTitle && (
                <Typography component="div" className="pb-20">
                    <Typography variant='h5' className="pb-8">{objectiveTitle.heading} :</Typography>
                    <ol>
                        {objectiveTitle.subText.map((point, index) => (
                            <li key={index}  className="body1_color"><Typography variant='body1'>{point}</Typography></li>
                        ))}
                    </ol>
                </Typography>
            )}
            {programOutcome && (
                <div>
                    <Typography variant='h5' className="pb-10">{programOutcome.heading} :</Typography>
                    <ol>
                        {programOutcome.subText.map((outcome, index) => (
                            <li key={index}  style={{ color:"#667085"}}><Typography variant='body1'>{outcome}</Typography></li>
                        ))}
                    </ol>
                </div>
            )}
        </section>
    )
}

export default Lawcourse;
