import React, { useState } from "react";
import { Grid, Typography, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";

const StudentPlacementPage = ({ placedStudents }) => {
  const displayedImages = placedStudents.images.slice(0, 4);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5" align="left" gutterBottom>
          {placedStudents.Title}
        </Typography>
        <Button variant="text" style={{ color: "#F15215" }} component={Link} to="/allStudents">
          See All
        </Button>
      </div>
      <Grid container justifyContent="center">
        {displayedImages.map((student) => (
          <Grid item key={student.id} sm={3}>
            <img
              src={student.url}
              alt={student.alt}
              style={{ height: "342px", width: "258px", borderRadius: "20px", cursor: "pointer" }}
              onClick={() => handleImageClick(student)}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedImage?.alt}</DialogTitle>
        <DialogContent>
          <img src={selectedImage?.url} alt={selectedImage?.alt} style={{ width: "100%" }} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StudentPlacementPage;
