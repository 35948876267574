import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

const AboutIQAC = () => {

  const About_IQAC_data = {
    ABOUT: {
        title: "ABOUT IQAC",
        description: 'An Internal Quality Assurance Cell (IQAC) has been established during the academic year 2013. It aims to develop a system for conscious, consistent and catalytic action to improve academic and administrative activities. The cell imparts mechanisms and procedures for ensuring the timely, efficient and progressive performance of academic, administrative and financial tasks. It also focuses on optimization and integration of modern methods of teaching, learning and evaluation and ensuring the adequacy, maintenance and functioning of the support services.'
    },
    // aqar: {
    //     title: "AQAR from 2012-13 to 16-17",
    //     aqar_data: [
    //         {
    //             "title": "AQAR-2012-13",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2012-13.doc",
    //         },
    //         {
    //             "title": "AQAR-2013-14",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2013-14.doc",
    //         },
    //         {
    //             "title": "AQAR-2014-15",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2014-15.doc",
    //         },
    //         {
    //             "title": "AQAR-2015-16",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2015-16.doc",
    //         },
    //         {
    //             "title": "AQAR-2016-17",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2016-17.doc",
    //         },
    //         {
    //             "title": "AQAR-2017-18",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2017-18.doc",
    //         },
    //         {
    //             "title": "AQAR-2018-19",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2018-19.doc",
    //         },
    //         {
    //             "title": "AQAR-2019-20",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2019-20.doc",
    //         },
    //         {
    //             "title": "AQAR-2020-21",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2020-21.doc",
    //         },
    //         {
    //             "title": "AQAR-2021-22",
    //             "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/AQAR-2021-22.doc",
    //         },
    //     ],
    // },

    aCalander1:
    {
        title: "Academic Calender 2017-18 & 2018-19",
        aCalander1_data: [
            {
                "title": "Anual Plan 2017-18",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2017-18.pdf"
            },
            {
                "title": "Anual Plan 2018-19",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2018-19.pdf"
            },
        ],

    },

    aCalander2:
    {
        title: "Academic Calender 2019-2023",
        aCalander2_data: [
            {
                "title": "Anual Plan 2019-20",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2019-20.pdf"
            },
            {
                "title": "Anual Plan 2020-21",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2020-21.pdf"
            },
            {
                "title": "Anual Plan 2021-22",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2021-22.pdf"
            },
            {
                "title": "Anual Plan 2022-23",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/anual-plan-2021-22.pdf"
            },
        ],
    },

    iqac:
    {
        title: "Internal Quality Assurance Cell (IQAC) Minutes & Taken Report 2019-2023",
        iqac_data: [
            {
                "title": "IQAC Minutes & Taken Report 2019-20",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2019-20.pdf"
            },
            {
                "title": "IQAC Minutes & Taken Report 2020-21",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2020-21.pdf"
            },
            {
                "title": "IQAC Minutes & Taken Report 2021-22",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2021-22.pdf"
            },
            {
                "title": "IQAC Minutes & Taken Report 2022-23",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/IQAC  MINUTES & ACTION TAKEN REPORT-2022-23.pdf"
            },
        ],
    },

    collegePolicy: {
        title: "College Policy",
        collegePolicy_data: [
            {
                "title": "4.4.2",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/IQAC/AboutIQAC/WhatsApp Image 2024-10-07 at 11.53.22 AM.pdf"
            }
        ]
    },
    Institutional_Distinctiveness:
    {
        title: "Institutional Distinctiveness",
        Institutional_Distinctiveness_data: [
            {
                "title": "7.3.1",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbralc/prod/IQAC/AboutIQAC/7.3.1 (1).pdf"
            }
        ]
    },

    sss:
    {
        title: "Student Satisfaction Survey (SSS) of 2017-2023",
        sss_data: [
            {
                "title": "SSS 2017-2018",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2017-18.doc"
            },
            {
                "title": "SSS 2018-2019",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2018-19.doc"
            },
            {
                "title": "SSS 2019-2020",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2019-20.doc"
            },
            {
                "title": "SSS 2020-2021",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2020-21.doc"
            },
            {
                "title": "SSS 2021-2022",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2021-22.doc"
            },
            {
                "title": "SSS 2022-2023",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/sss-2022-23.pdf"
            },
        ]
    },
    Course_Outcomes: {
        title: "Course Outcomes",
        Course_Outcomes_data: [
            {
                "title": "CO",
                "path": "https://dbraeiassetmanagement.blob.core.windows.net/dbradc/prod/IQAC/course-outcomes.pdf"
            }
        ]
    }
}
  return (
    <section className="container py-4">
      <Typography variant="h6" component="div" sx={{ my: 2 }}>
        {About_IQAC_data.ABOUT.title}
      </Typography>
      <Typography variant="p" component="div" sx={{ my: 2 }}>
        {About_IQAC_data.ABOUT.description}
      </Typography>
      {/* <div className="my-4">
        <div>
          <h6>{About_IQAC_data.aqar.title}</h6>
        </div>
        {About_IQAC_data.aqar.aqar_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div> */}
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.aCalander1.title}</h6>
        </div>
        {About_IQAC_data.aCalander1.aCalander1_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
                // onClick={() => window.open(items.path)}
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.aCalander2.title}</h6>
        </div>
        {About_IQAC_data.aCalander2.aCalander2_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.iqac.title}</h6>
        </div>
        {About_IQAC_data.iqac.iqac_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.collegePolicy.title}</h6>
        </div>
        {About_IQAC_data.collegePolicy.collegePolicy_data.map((items) => {
          return (
            <div>
              <small
                className="custom-cursor-pointer text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.Institutional_Distinctiveness.title}</h6>
        </div>
        {About_IQAC_data.Institutional_Distinctiveness.Institutional_Distinctiveness_data.map(
          (items) => {
            return (
              <div>
                <small
                  className="text-decoration custom-cursor-pointer"
                >
                  {items.title}
                </small>
              </div>
            );
          }
        )}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.sss.title}</h6>
        </div>
        {About_IQAC_data.sss.sss_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
      <div className="my-4">
        <div>
          <h6>{About_IQAC_data.Course_Outcomes.title}</h6>
        </div>
        {About_IQAC_data.Course_Outcomes.Course_Outcomes_data.map((items) => {
          return (
            <div>
              <small
                className="disabled text-decoration"
              >
                {items.title}
              </small>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default AboutIQAC;
