import React, { useState } from "react";
// import Button from "@mui/material/Button";
import { Typography, Button } from "@mui/material";

const ExpandableText = ({ text, initialChars, showFullText }) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAllClick = () => {
    setShowAll(!showAll);
  };

  const truncatedText = showAll? text : text.slice(0, initialChars) + (text.length > initialChars? "..." : "");

  return (
    <div>
      <Typography variant="body1" className="body1_color">{truncatedText}{!showAll && text.length > initialChars && (
        <Button onClick={handleShowAllClick}>Read More</Button>
      )}
      {showAll && (
        <Button onClick={handleShowAllClick}>Read Less</Button>
      )}</Typography>
   
    </div>
  );
};

export default ExpandableText;
