import React, { useState } from "react";
import "../../Styles/HS.css";
import {Grid , Typography} from "@mui/material";

import ExpandableText from "./ExpandableText";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const HS = (Props) => {
  const HOD_Message_Data = Props.HS_DATA;
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <section id="HsStyle" className="container my-3">
      <Typography variant="h4" className="text-center mb-4">{HOD_Message_Data.tittle}</Typography>
      <Grid container className="description">
        <Grid item xs={12} md={6} className="pl-3 d-flex align-items-top item">
          <div>
            <Grid
              item
              md={10}
              className="image-container"
              style={{ float: "left", margin: "0px", width: "100%", height: "auto" }}
            >
              <img
                src={
                  HOD_Message_Data?.image ||
                  "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png"
                }
                className="w-100 cursor-pointer item-image"
                onClick={openDialog}
                onError={(e) =>
                  (e.target.src =
                    "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                }
                alt="HOD"
              />

              <Typography variant="h6">{HOD_Message_Data.name}</Typography>
              <Typography variant="subtitle2" className="text-primary">{HOD_Message_Data.designation}</Typography>
            </Grid>
            <ExpandableText text={HOD_Message_Data.description} initialChars={800} showFullText={false} />
          </div>
        </Grid>
      </Grid>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          <ExpandableText text={HOD_Message_Data.description} initialChars={350} showFullText={false} />
        </DialogTitle>
        <DialogContent className="row">
          <Typography component="img"
            src={HOD_Message_Data?.image}
            alt="Image Popup"
            className="cursor-pointer w-50 d-flex" 
          />
          <Typography component="h4">{HOD_Message_Data.name}</Typography>
          <Typography component="subtitle1" className="text-primary">{HOD_Message_Data.designation}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default HS;
